<template>
    <!-- Page-Title -->
    <div class="row">
        <div class="col-sm-12">
            <div class="page-title-box">
                <h2 class="page-title">{{ title }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImunisasiGrafik',
    data() {
        return {
            title: 'Grafik Data Imunisasi'
        };
    },
};
</script>

<style lang="css" scoped>

</style>
